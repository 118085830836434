import { AGREEMENT, CUSTOM_SCORE, DROPDOWN, GENDER, PLAIN, RADIO, RANDOM, SCORE, STAR, SLIDER } from './questionTypes';

export const AUTO_SCROLL_QUESTION_TYPES = [
  AGREEMENT,
  CUSTOM_SCORE,
  DROPDOWN,
  GENDER,
  PLAIN,
  RADIO,
  RANDOM,
  SCORE,
  STAR,
  SLIDER,
];
