import { INCOMPLETE_SURVEYS } from '../constants';
import localStorage from '../../../utils/localStorage';

export const getIncompleteSurveys = () => localStorage.getItem(INCOMPLETE_SURVEYS) ?? [];

export const getIncompleteSurvey = (nodeId, flowId) => {
  const incompleteSurveys = getIncompleteSurveys();

  return incompleteSurveys.find((survey) => survey.nodeId === nodeId && survey.flowId === flowId);
};

export const addIncompleteSurvey = (feedbackId, nodeId, flowId) => {
  const incompleteSurveys = getIncompleteSurveys();

  incompleteSurveys.splice(
    incompleteSurveys.findIndex((survey) => survey.nodeId === nodeId && survey.flowId === flowId),
    1,
  );

  incompleteSurveys.push({
    feedbackId,
    nodeId,
    flowId,
  });

  localStorage.setItem(INCOMPLETE_SURVEYS, incompleteSurveys);
};

export const removeIncompleteSurvey = (feedbackId) => {
  const incompleteSurveys = getIncompleteSurveys();
  const updatedIncompleteSurveys = incompleteSurveys.filter((survey) => survey.feedbackId !== feedbackId);

  localStorage.setItem(INCOMPLETE_SURVEYS, updatedIncompleteSurveys);
};
